import { ButtonWithTooltip } from "@mdxeditor/editor";

//Example: https://github.com/facebook/lexical/blob/main/examples/react-rich/src/plugins/ToolbarPlugin.tsx

interface SaveChangesProps {
	onClick?: () => void;
}

/**
 * Enables user to Update the database with the file that has been modified.
 * @param {function} props.onClick - onClick function attribute for the button.
 */
function SaveChanges({ onClick }: SaveChangesProps) {
	//TODO: when clicked - the contents of the editor should save in the Database via an API call.
	return (
		<ButtonWithTooltip className="saveChanges" title="Save" onClick={onClick}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				{/* Disk Outline */}
				<path
					d="M3 5.75C3 4.23122 4.23122 3 5.75 3H15.7145C16.5764 3 17.4031 3.34241 18.0126 3.9519L20.0481 5.98744C20.6576 6.59693 21 7.42358 21 8.28553V18.25C21 19.7688 19.7688 21 18.25 21H5.75C4.23122 21 3 19.7688 3 18.25V5.75Z"
					stroke="currentColor"
				/>

				{/* Label */}
				<path d="M7.5 13.5H16.5V19.5H7.5V13.5Z" stroke="currentColor" />

				{/* Disk cover */}
				<path d="M8.5 4.5H14.5V8H8.5V4.5Z" stroke="currentColor" />

				<rect x="0" y="0" width="24" height="24" fillOpacity="0" />
			</svg>
		</ButtonWithTooltip>
	);
}

export default SaveChanges;
