import React from "react";
import Hotjar from "../components/Hotjar";
import StripePricingTable from "../components/StripePricingTable";

const MaterialPricingIcon = ({ className = "w-8 h-8 text-green-600" }) => (
	<span className={`material-symbols-outlined ${className}`}>payments</span>
);

const PricingPage = () => {
	return (
		<>
			<Hotjar />
			<div className="min-h-screen bg-gray-50">
				<nav className="bg-white shadow top-0 sticky">
					<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
						<div className="flex justify-between h-16">
							<div className="flex items-center">
								<MaterialPricingIcon />
								<span className="ml-2 text-xl font-semibold">
									Pricing Plans
								</span>
							</div>
						</div>
					</div>
				</nav>

				<main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
					<div className="flex flex-col space-y-12">
						{/* Hero Section */}
						<div className="text-center">
							<h1 className="text-4xl font-bold text-gray-900 mb-4">
								Simple, Transparent Pricing
							</h1>
							<p className="text-xl text-gray-600 max-w-2xl mx-auto">
								Everything you need to succeed in your job search journey
							</p>
						</div>

						{/* Main Pricing Card */}
						<div className="bg-white py-4 rounded-xl shadow-lg">
							<StripePricingTable />
						</div>

						{/* Features Grid */}
						<div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
							<div className="bg-white rounded-xl shadow-lg p-6">
								<span className="material-symbols-outlined text-3xl text-green-600 mb-4">
									security
								</span>
								<h3 className="text-lg font-semibold mb-2">
									Money Back Guarantee
								</h3>
								<p className="text-gray-600">
									30-day satisfaction guarantee or your money back
								</p>
							</div>
							<div className="bg-white rounded-xl shadow-lg p-6">
								<span className="material-symbols-outlined text-3xl text-green-600 mb-4">
									update
								</span>
								<h3 className="text-lg font-semibold mb-2">Regular Updates</h3>
								<p className="text-gray-600">
									Continuous improvements and new features
								</p>
							</div>
							<div className="bg-white rounded-xl shadow-lg p-6">
								<span className="material-symbols-outlined text-3xl text-green-600 mb-4">
									support_agent
								</span>
								<h3 className="text-lg font-semibold mb-2">Premium Support</h3>
								<p className="text-gray-600">24/7 access to our support team</p>
							</div>
						</div>

						{/* FAQ Section */}
						<div className="bg-white rounded-xl shadow-lg p-8">
							<h2 className="text-2xl font-bold mb-6">
								Frequently Asked Questions
							</h2>
							<div className="space-y-6 [&_p]:text-gray-600 [&_h3]:font-semibold [&_h3]:mb-2">
								<div>
									<h3>How long is my subscription valid?</h3>
									<p>
										From the date of your initial monthly payment, the monthly
										pricing option gives you full access to all features for 1
										month.
									</p>
									<p>
										The yearly pricing option gives you the same access for 12
										months.
									</p>
								</div>
								<div>
									<h3>Can I cancel my subscription?</h3>
									<p>
										Yes, you can cancel anytime and retain access until the end
										of your current billing cycle.
									</p>
									<p>
										During that time you can also choose to renew your
										subscription and it will not be canceled.
									</p>
								</div>
								<div>
									<h3>Are there any usage limits?</h3>
									<p>
										No, you get unlimited access to all features during your
										subscription period.
									</p>
								</div>
								{/* <div>
                <h3 className="font-semibold mb-2">Do you offer student discounts?</h3>
                <p className="text-gray-600">Yes, students can get a 20% discount with valid student ID verification.</p>
              </div> */}
							</div>
						</div>

						{/* CTA Section */}
						<div className="bg-gradient-to-r from-green-500 to-green-300 rounded-xl shadow-lg p-8 text-center text-white">
							<h2 className="text-3xl font-bold mb-4">
								Ready to Transform Your Job Search?
							</h2>
							<p className="text-xl mb-6">
								Join thousands of successful job seekers today
							</p>
							{/* <button className="bg-white text-green-600 px-8 py-3 rounded-lg font-semibold hover:bg-green-50 transition-colors">
              Start Your Journey
            </button> */}
						</div>
					</div>
				</main>
			</div>
		</>
	);
};

export default PricingPage;
