import { createContext } from "react";

type SwimlanesContextType = {
	moveJobToDone: (jobId: string) => void;
};

const SwimlanesContext = createContext<SwimlanesContextType | null>(null);

export type { SwimlanesContextType };
export default SwimlanesContext;
