import React, { FC, useContext, useState } from "react";
import Modal from "../Modal";
import "./addJobModal.css";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { APPLIED } from "../Swimlanes";
import ApiInterface from "../services/api-client/api-interface";
import {
	API_HTTP,
	resumeAnalyzerApiEndpoints
} from "../services/api-client/endpoints";
import { AuthContext, AuthContextType } from "../context/AuthProvider";
import { User } from "firebase/auth";
import LoadingWheel from "../components/LoadingWheel";
import { toast, ToastContainer } from "react-toastify";
import UpgradeButton from "../components/UpgradeButton";

const UpgradePrompt: FC = () => (
	<div className="flex flex-col gap-y-2 justify-center mt-4">
		<p className="font-semibold text-2xl whitespace-nowrap">
			Like what you see already?
		</p>
		<p>
			<UpgradeButton className="block m-auto w-fit" />
		</p>
	</div>
);

interface AddJobModalProps {
	isOpen: boolean;
	onClose: () => void;
	/**
	 * If enabled, a portion of the modal displays a message, with a button, encouraging the user to upgrade.
	 * @defaultValue `false`
	 */
	showUpgradeMessage?: boolean;
	/**
	 * If enabled, the form portion of the modal is hidden.
	 * @defaultValue `false`
	 */
	hideForm?: boolean;
}

export interface AddJobModalData {
	jobTitle: string;
	jobDescription: string;
	company: string;
}

export default function AddJobModal({
	isOpen,
	onClose,
	showUpgradeMessage = false,
	hideForm = false
}: AddJobModalProps) {
	// Assume this is being used within an AuthProvider
	const authContext = useContext(AuthContext) as AuthContextType;
	// Assume the user is authenticated
	const user = authContext.user as User;

	const initialFormState = { jobTitle: "", jobDescription: "", company: "" };

	const [formState, setFormState] = useState<AddJobModalData>(initialFormState);

	const queryClient = useQueryClient();
	const addJobMutation = useMutation({
		mutationFn: ({
			jobTitle,
			jobDescription,
			company
		}: AddJobModalData): Promise<any> => {
			const apiClient = new ApiInterface(API_HTTP.resumeAnalyzer, user);
			return apiClient.post(
				resumeAnalyzerApiEndpoints.jobs,
				{
					job: {
						title: jobTitle,
						jobDescription,
						company,
						state: APPLIED
					}
				},
				{},
				{
					// Disable axios timeout
					// TODO: Change this back to a reasonable timeout later
					timeout: 0
				}
			);
		}
	});

	// Update state when form is changed
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setFormState({
			...formState,
			[name]: value
		});
	};

	const handleTextAreaChange = (
		event: React.ChangeEvent<HTMLTextAreaElement>
	) => {
		const { name, value } = event.target;
		setFormState({
			...formState,
			[name]: value
		});
	};

	// Execute form action and close form if successful when submitting
	const handleSubmit = (event: React.FormEvent) => {
		// Do nothing if the form is hidden
		if (hideForm) {
			return;
		}
		event.preventDefault();
		addJobMutation.mutate(formState, {
			onSuccess: (data: any): void => {
				const { jobId, order } = data;
				const { jobTitle: title, jobDescription, company } = formState;
				// Directly update the react-query cache here to refresh the swimlanes
				queryClient.setQueryData(
					["user", user.uid, "jobs"],
					({ jobs }: any) => {
						return {
							jobs: [
								...jobs,
								{
									jobId,
									state: APPLIED,
									creationTimestamp: new Date(),
									title,
									company,
									order
								}
							]
						};
					}
				);
				// Directly update the react-query cache for the job details too
				// This is so we don't have to wait for the job description
				// TODO: This might need to be deleted in the future if further processing is done to the job description
				queryClient.setQueryData(["user", user.uid, "job", jobId], {
					job: {
						jobId,
						state: APPLIED,
						creationTimestamp: new Date(),
						jobDescription,
						title,
						company,
						order
					}
				});
				// Close the modal
				handleClose();
				// Show successful toast message
				toast.success("New job added!", {
					containerId: "toast-main",
					theme: "colored"
				});
			},
			onError: (): void => {
				toast.error(
					<span>
						Oh no! Something went wrong!
						<br />
						Please try again
					</span>,
					{
						containerId: "toast-addjobmodal",
						theme: "colored",
						hideProgressBar: true
					}
				);
			}
		});
	};

	const handleClose = () => {
		// Clear toast messages
		toast.dismiss({ containerId: "toast-addjobmodal" });
		// Close modal
		onClose();
		// Reset form unless mutation is pending
		!addJobMutation.isPending && setFormState(initialFormState);
	};

	return (
		<Modal isOpen={isOpen} onClose={handleClose} hasCloseButton={true}>
			<ToastContainer containerId="toast-addjobmodal" position="top-center" />
			<div className="w-72">
				{showUpgradeMessage && <UpgradePrompt />}
				{!hideForm && (
					<form className="addJobForm" onSubmit={handleSubmit}>
						<div className="add-job-form-row">
							<input
								id="company"
								name="company"
								autoComplete="off"
								placeholder=""
								value={formState.company}
								onChange={handleInputChange}
								disabled={addJobMutation.isPending}
								required
							/>
							<label htmlFor="company">Company</label>
						</div>
						<div className="add-job-form-row">
							<input
								id="jobTitle"
								name="jobTitle"
								autoComplete="off"
								placeholder=""
								value={formState.jobTitle}
								onChange={handleInputChange}
								disabled={addJobMutation.isPending}
								required
							/>
							<label htmlFor="jobTitle">Job Title</label>
						</div>
						<div className="add-job-form-row">
							{/* <div className="add-job-textbox-form-row"> */}
							<textarea
								id="jobDescription"
								name="jobDescription"
								autoComplete="off"
								placeholder=" "
								value={formState.jobDescription}
								onChange={handleTextAreaChange}
								disabled={addJobMutation.isPending}
								required
							/>
							<label htmlFor="jobDescription">Job Description</label>
							{/* </div> */}
						</div>

						<div className="add-job-form-row">
							{/* Disable button while mutation is pending */}
							<button disabled={addJobMutation.isPending}>
								{addJobMutation.isPending && (
									<LoadingWheel
										svgClasses={["add-job-form-row-loadingwheel"]}
									/>
								)}
								Add Job
							</button>
						</div>
					</form>
				)}
			</div>
		</Modal>
	);
}
