const MAX_REGENERATIONS_DEFAULT = 3;
export const ERROR_NO_REMAINING_REGEN = "No regenerations remaining.";

// Helper function to manage the regeneration count in localStorage
export const GetRemainingRegenerations = (userId: string): number => {
	const storedValue = localStorage.getItem(`regenCount-${userId}`);
	return storedValue ? parseInt(storedValue, 10) : MAX_REGENERATIONS_DEFAULT; // Default to 3
};

const SetRemainingRegenerations = (userId: string, count: number): void => {
	localStorage.setItem(`regenCount-${userId}`, count.toString());
};

//Consume a Regen
// Throw if unable to comply
export const UseRemainingRegenerations = (userId: string): void => {
	const remainingRegens = GetRemainingRegenerations(userId);
	if (remainingRegens <= 0) {
		throw new Error(ERROR_NO_REMAINING_REGEN);
	}

	SetRemainingRegenerations(userId, remainingRegens - 1); // Decrement count
};
