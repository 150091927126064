import React from "react";
import ArrowRight from "../../components/Icons/ArrowRightIcon";
import TrackingIcon from "../../components/Icons/TrackingIcon";
import Hotjar from "../../components/Hotjar";
import FreeTrialButton from "../../components/FreeTrialButton";

const TrackingPage = () => {
	return (
		<>
			<Hotjar />
			<div className="min-h-screen bg-gray-50">
				<nav className="bg-white shadow sticky top-0">
					<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
						<div className="flex justify-between h-16">
							<div className="flex items-center">
								<TrackingIcon />
								<span className="ml-2 text-xl font-semibold">
									Smart Job Application Tracking
								</span>
							</div>
						</div>
					</div>
				</nav>

				<main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
					<div className="space-y-12">
						{/* Hero Section */}
						<div className="text-center">
							<h1 className="text-4xl font-bold text-gray-900 mb-4">
								Never Miss a Job Application Update
							</h1>
							<p className="text-xl text-gray-600 max-w-3xl mx-auto">
								Track every application, interview, and follow-up in one
								organized dashboard.
							</p>
						</div>

						{/* Screen Shot */}
						<div className="bg-white rounded-xl shadow-lg p-8">
							<h2 className="text-2xl font-bold mb-6">Application Tracking</h2>
							<div className="space-y-6 pl-6">
								<img src={"../tracking.webp"} alt="tracking screenshot" />
							</div>
						</div>

						{/* Features */}
						<div className="grid md:grid-cols-2 gap-8">
							<div className="bg-white rounded-xl shadow-lg p-8">
								<h2 className="text-2xl font-bold mb-6">Smart Features</h2>
								<ul className="space-y-4">
									<li className="flex items-start">
										<ArrowRight className="mt-1 mr-2 text-purple-600" />
										<div>
											<h3 className="font-semibold">Intutive Status Updates</h3>
											<p className="text-gray-600">
												Track application progress with smart status management
											</p>
										</div>
									</li>
									<li className="flex items-start">
										<ArrowRight className="mt-1 mr-2 text-purple-600" />
										<div>
											<h3 className="font-semibold">Follow-up Reminders</h3>
											<p className="text-gray-600">
												Timely notifications for important follow-ups
											</p>
										</div>
									</li>
								</ul>
							</div>

							<div className="bg-white rounded-xl shadow-lg p-8">
								<h2 className="text-2xl font-bold mb-6">Organization Tools</h2>
								<ul className="space-y-4">
									<li className="flex items-start">
										<ArrowRight className="mt-1 mr-2 text-purple-700" />
										<div>
											<h3 className="font-semibold">Modern Interface</h3>
											<p className="text-gray-600">
												State-of-the-art simple to use Drag-n-Drop Interface
											</p>
										</div>
									</li>
									<li className="flex items-start">
										<ArrowRight className="mt-1 mr-2 text-purple-700" />
										<div>
											<h3 className="font-semibold">Notes and Feedback</h3>
											<p className="text-gray-600">
												Record interview feedback and important details
											</p>
										</div>
									</li>
									<li className="flex items-start">
										<ArrowRight className="mt-1 mr-2 text-purple-700" />
										<div>
											<h3 className="font-semibold">Document Management</h3>
											<p className="text-gray-600">
												Store résumés and related documents for each application
											</p>
										</div>
									</li>
								</ul>
							</div>
						</div>

						{/* CTA Section */}
						<div className="bg-purple-600 rounded-xl shadow-lg p-8 text-center text-white">
							<h2 className="text-3xl font-bold mb-4">
								Stay Organized, Get Hired
							</h2>
							<p className="text-xl mb-6">
								Start tracking your job applications today
							</p>
							<FreeTrialButton className="feature-free-trial-button tracking-free-trial-button glint-link">
								Get Organized
							</FreeTrialButton>
						</div>
					</div>
				</main>
			</div>
		</>
	);
};

export default TrackingPage;
