import { FC } from "react";

const SupportEmailAddressLink: FC = () => (
	<a
		className="underline"
		href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS}`}
	>
		{process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS}
	</a>
);

export default SupportEmailAddressLink;
