import { Link } from "react-router-dom";

export default function DebugPage() {
	return (
		<div className="p-4">
			<h1 className="text-xl">~Super Secret Debug Page~</h1>
			<ul className="list-disc list-inside pl-2 [&_a]:underline">
				<li>
					<Link to="/dnd">Swimlanes Page</Link>
				</li>
				<li>
					<Link to="/login">Login Page</Link>
				</li>
				<li>
					<Link to="/pricing">Pricing Page</Link>
				</li>
				<li>
					<Link to="/activation">Activation Page</Link>
				</li>
				<li>
					<a href="/exceptions/404.html">404 Page</a>
				</li>
				<li>
					<Link to="/newLanding">New Landing</Link>
				</li>
				<li>
					<Link to="/landing">Old Landing</Link>
				</li>
				<li>
					<Link to="/terms">Terms and Conditions</Link>
				</li>
				<li>
					<Link to="/privacy">Privacy Policy</Link>
				</li>
			</ul>
		</div>
	);
}
