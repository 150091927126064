import { GoogleAuthProvider, User, UserCredential } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { ReactNode, useContext, useState } from "react";
import { AuthContext, AuthContextType } from "../context/AuthProvider";
import { GoogleLoginButton } from "react-social-login-buttons";
import { toast } from "react-toastify";
import React from "react";
import { useMutation } from "@tanstack/react-query";
import ApiInterface from "../services/api-client/api-interface";
import {
	API_HTTP,
	resumeAnalyzerApiEndpoints
} from "../services/api-client/endpoints";
import { AxiosError } from "axios";
import SupportEmailAddressLink from "../components/SupportEmailAddressLink";

function LoginPage() {
	const [agreements, setAgreements] = useState({
		terms: false,
		privacy: false
	});

	const { login, logout, loading, user } = useContext(
		AuthContext
	) as AuthContextType;
	const navigate = useNavigate();

	const firstTimeSignInMutation = useMutation({
		mutationFn: async ({
			authenticatedUser,
			campaignId
		}: {
			authenticatedUser: User;
			campaignId?: string;
		}): Promise<void> => {
			const apiClient = new ApiInterface(
				API_HTTP.resumeAnalyzer,
				authenticatedUser
			);
			return await apiClient.post(
				resumeAnalyzerApiEndpoints.orders,
				{
					action: "create-free-user",
					campaignId
				},
				{},
				{ timeout: 120 * 1000 }
			);
		}
	});

	const showLoginErrorToast = (): void => {
		toast.error(
			<div className="px-2 text-center">
				<b>Something went wrong!</b>
				<p>Please sign out and try again.</p>
				<p>
					Contact <SupportEmailAddressLink /> if the problem persists.
				</p>
			</div>,
			{
				containerId: "toast-main",
				position: "top-center",
				theme: "colored",
				autoClose: false
			}
		);
	};

	const loginHandler = () => {
		if (!(agreements.terms && agreements.privacy)) {
			toast.error(
				<span>
					<p>
						Please agree to both the{" "}
						<ul>
							<li>Terms of Service</li>
							<li>Privacy Policy</li>
						</ul>{" "}
						before continuing.
					</p>
				</span>,
				{
					containerId: "toast-main",
					theme: "colored",
					hideProgressBar: true
				}
			);
			//Long exit - don't call the google provider.
			return;
		}

		const googleProvider = new GoogleAuthProvider();
		googleProvider.setCustomParameters({
			prompt: "select_account"
		});
		login(googleProvider)
			.then(async ({ user }: UserCredential) => {
				const token = await user.getIdTokenResult();
				/*
					If this claim is missing from the JWT, the user is (probably) signing in for the first time.
					Note that adding the claim on the backend won't add it to this token until it is refreshed,
					but that is okay because we only check the claim during login so the token is fresh
				*/
				if (!token.claims.has_user_profile) {
					// Check for campaign query parameter
					const searchParams = new URLSearchParams(window.location.search);
					const campaignId = searchParams.get("campaign") ?? undefined;
					// Trigger POST request to orders endpoint
					return firstTimeSignInMutation.mutateAsync({
						authenticatedUser: user,
						campaignId
					});
				}
			})
			// Ignore 409 CONFLICT status codes from firstTimeSignInMutation
			.catch((err: any) => {
				if (
					err instanceof AxiosError &&
					err.name === "AxiosError" &&
					err.code === "ERR_BAD_REQUEST" &&
					err.status === 409
				) {
					return;
				} else {
					throw err;
				}
			})
			// Redirect to the swimlanes page
			// The PrivateRouteWrapper will kick the user back to a fallback page if they (for some reason) don't have access
			.then(() => navigate("/dnd"))
			// Handle other errors
			.catch((error) => {
				// Closing the popup is not a "real" error
				if (error.code === "auth/popup-closed-by-user") {
					return;
				} else {
					// TODO: Should we log the error to the console?
					showLoginErrorToast();
				}
			});
	};

	const uxLoadingSpinner = (): ReactNode => {
		return (
			<div className="mt-2">
				<p className="text-center text-sm text-gray-600">
					{/* See: https://github.com/firebase/firebase-js-sdk/issues/8061#issuecomment-2047370693 */}
					Please wait up to 10 seconds if the popup was closed without logging
					in
				</p>
				<div className="relative h-20 my-2">
					<div
						role="status"
						className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center"
					>
						<svg
							aria-hidden="true"
							className="w-10 h-10 text-slate-200 animate-spin fill-cyan-400"
							viewBox="0 0 100 101"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
								fill="currentColor"
							/>
							<path
								d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
								fill="currentFill"
							/>
						</svg>
						<span className="sr-only">Loading...</span>
					</div>
				</div>
			</div>
		);
	};
	const uxLogoutButton = (): ReactNode => {
		return (
			<div className="mt-2">
				<p className="text-center text-gray-600 mb-5">
					You are already logged in
				</p>
				<div className="mb-5 [&>button]:rounded-xl [&>button]:overflow-hidden [&>button]:shadow-md hover:[&>button]:shadow-lg [&>button]:transition-shadow">
					<button
						type="button"
						className="mx-auto w-24 block p-1 text-gray-100 font-semibold rounded bg-blue-500"
						onClick={() => navigate("/dnd")}
					>
						Continue
					</button>
				</div>
				<div className="mb-8 [&>button]:rounded-xl [&>button]:overflow-hidden [&>button]:shadow-md hover:[&>button]:shadow-lg [&>button]:transition-shadow">
					<button
						type="button"
						className="mx-auto w-24 block p-1 text-gray-600 font-semibold rounded bg-zinc-200"
						// TODO: Handle errors
						onClick={logout}
					>
						Log Out
					</button>
				</div>
			</div>
		);
	};

	const uxLoginButton = (): ReactNode => {
		return (
			<div className="mt-2">
				{/* Terms and Privacy Checkboxes */}
				<div className="space-y-3 mb-6 px-1">
					<div className="flex items-start space-x-2">
						<div className="relative flex items-start">
							<input
								type="checkbox"
								id="terms"
								checked={agreements.terms}
								onChange={(e) =>
									setAgreements((prev) => ({
										...prev,
										terms: e.target.checked
									}))
								}
								className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 mt-0.5"
							/>
						</div>
						<label
							htmlFor="terms"
							className="text-xs text-gray-600 cursor-pointer select-none"
						>
							I agree to the{" "}
							<button
								onClick={() => window.open("/terms", "_blank")}
								className="text-blue-600 hover:underline"
							>
								Terms of Service
							</button>
						</label>
					</div>

					<div className="flex items-start space-x-2">
						<div className="relative flex items-start">
							<input
								type="checkbox"
								id="privacy"
								checked={agreements.privacy}
								onChange={(e) =>
									setAgreements((prev) => ({
										...prev,
										privacy: e.target.checked
									}))
								}
								className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 mt-0.5"
							/>
						</div>
						<label
							htmlFor="privacy"
							className="text-xs text-gray-600 cursor-pointer select-none"
						>
							I agree to the{" "}
							<button
								onClick={() => window.open("/privacy", "_blank")}
								className="text-blue-600 hover:underline"
							>
								Privacy Policy
							</button>
						</label>
					</div>
				</div>

				{/* Login button */}
				<div className="mb-8 [&>button]:rounded-xl [&>button]:overflow-hidden [&>button]:shadow-md hover:[&>button]:shadow-lg [&>button]:transition-shadow">
					<GoogleLoginButton onClick={loginHandler}>
						Sign in with Google
					</GoogleLoginButton>
				</div>
			</div>
		);
	};

	return (
		<div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
			{/* Phone container */}
			<div className="w-80 h-[600px] bg-white rounded-[40px] shadow-xl overflow-hidden border-8 border-gray-800 relative">
				{/* Status bar */}
				<div className="h-6 bg-gray-800" />

				{/* Content container */}
				<div className="h-full bg-white flex flex-col">
					{/* Dark banner with logo section */}
					<div className="relative bg-gray-900 pt-8 pb-16">
						<div className="text-center">
							<h1 className="text-2xl font-bold text-white mb-2">JobMap</h1>
							<p className="text-gray-300 text-sm">Your career. Navigated.</p>
						</div>
					</div>

					{/* Overlapping mascot circle */}
					<div className="relative -mt-12 px-6">
						<div className="w-24 h-24 mx-auto bg-white rounded-full p-1 shadow-lg">
							<div className="w-full h-full bg-blue-500 rounded-full overflow-hidden">
								{/* Placeholder for Mappy mascot */}
								<img
									src={require("../static/mappy_fullbody.webp")}
									alt="Mappy mascot"
									className="w-full h-full object-cover"
								/>
							</div>
						</div>
					</div>

					{/* Main content */}
					<div className="px-6 flex-1 flex flex-col">
						{/* Welcome text */}
						<div className="text-center mt-6 mb-8">
							<h2 className="text-xl text-gray-700">I’m Mappy</h2>
							<p className="text-gray-600 mt-2">
								{" "}
								I’m looking forward to helping you find your way.
							</p>
						</div>

						{/* Show the loading spinner if OAuth is still loading or the page is waiting for an API response during first-time sign-in */}
						{loading || firstTimeSignInMutation.isPending
							? uxLoadingSpinner()
							: user
								? uxLogoutButton()
								: uxLoginButton()}
					</div>
				</div>
			</div>
		</div>
	);
}

export default LoginPage;
