// React imports
import { FC } from "react";

const CustomerPortalButton: FC = () => {
	return (
		<a
			href={process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL}
			target="_blank"
			rel="noreferrer"
			className="bg-blue-600 font-semibold py-1 px-2 rounded text-white"
		>
			Manage Subscription
		</a>
	);
};

export default CustomerPortalButton;
