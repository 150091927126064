import ResumeIcon from "../../components/Icons/ResumeIcon";
import Hotjar from "../../components/Hotjar";
import React from "react";
import FreeTrialButton from "../../components/FreeTrialButton";

const ResumePage = () => {
	return (
		<>
			<Hotjar />
			<div className="min-h-screen bg-gray-50">
				<nav className="bg-white shadow sticky top-0">
					<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
						<div className="flex justify-between h-16">
							<div className="flex items-center">
								<ResumeIcon />
								<span className="ml-2 text-xl font-semibold">
									AI Résumé Processing
								</span>
							</div>
						</div>
					</div>
				</nav>

				<main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
					<div className="space-y-12">
						<div className="text-center">
							<h1 className="text-4xl font-bold text-gray-900 mb-4">
								AI-Powered Résumé Analysis
							</h1>
							<p className="text-xl text-gray-600 max-w-2xl mx-auto">
								Use the most intelligent models to build insights into your
								résumé
							</p>
						</div>

						{/* <div className="grid md:grid-cols-3 gap-6"> */}
						<div className="grid md:grid-cols-2 gap-6">
							<div className="bg-white rounded-xl shadow-lg p-6">
								<span className="material-symbols-outlined text-3xl text-green-600 mb-4">
									search
								</span>
								<h3 className="text-lg font-semibold mb-2">Deep Analysis</h3>
								<p className="text-gray-600">
									State-of-the-art parsing of skills, experience, and
									qualifications
								</p>
							</div>
							<div className="bg-white rounded-xl shadow-lg p-6">
								<span className="material-symbols-outlined text-3xl text-green-600 mb-4">
									psychology
								</span>
								<h3 className="text-lg font-semibold mb-2">Smart Authorship</h3>
								<p className="text-gray-600">
									AI-powered system for drafting documents
								</p>
							</div>
						</div>

						<div className="bg-white rounded-xl shadow-lg p-8">
							<h2 className="text-2xl font-bold mb-6">AI Capabilities</h2>
							<div className="grid md:grid-cols-2 gap-8">
								<div className="space-y-4">
									<div className="flex items-start gap-4">
										<div className="w-10 h-10 bg-green-100 rounded-lg flex items-center justify-center">
											<span className="material-symbols-outlined text-green-600">
												insights
											</span>
										</div>
										<div>
											<h3 className="font-semibold">Skill Analysis</h3>
											<p className="text-gray-600">
												Identify skills for target positions
											</p>
										</div>
									</div>
									<div className="flex items-start gap-4">
										<div className="w-10 h-10 bg-green-100 rounded-lg flex items-center justify-center">
											<span className="material-symbols-outlined text-green-600">
												visibility
											</span>
										</div>
										<div>
											<h3 className="font-semibold">ATS Optimization</h3>
											<p className="text-gray-600">
												Ensure compatibility with applicant tracking systems
											</p>
										</div>
									</div>
									<div className="flex items-start gap-4">
										<div className="w-10 h-10 bg-green-100 rounded-lg flex items-center justify-center">
											<span className="material-symbols-outlined text-green-600">
												trending_up
											</span>
										</div>
										<div>
											<h3 className="font-semibold">Industry Insights</h3>
											<p className="text-gray-600">
												Tailored recommendations based on industry standards
											</p>
										</div>
									</div>
								</div>

								<div className="bg-gray-50 p-6 rounded-lg">
									<h3 className="font-semibold mb-4">Résumé Score</h3>
									<div className="space-y-4">
										<div>
											<div className="flex justify-between mb-1">
												<span>Content Quality</span>
												<span>95%</span>
											</div>
											<div className="w-full bg-gray-200 rounded-full h-2">
												<div
													className="bg-green-600 h-2 rounded-full"
													style={{ width: "95%" }}
												></div>
											</div>
										</div>
										<div>
											<div className="flex justify-between mb-1">
												<span>Keyword Match</span>
												<span>82%</span>
											</div>
											<div className="w-full bg-gray-200 rounded-full h-2">
												<div
													className="bg-green-600 h-2 rounded-full"
													style={{ width: "82%" }}
												></div>
											</div>
										</div>
										<div>
											<div className="flex justify-between mb-1">
												<span>ATS Compatibility</span>
												<span>88%</span>
											</div>
											<div className="w-full bg-gray-200 rounded-full h-2">
												<div
													className="bg-green-600 h-2 rounded-full"
													style={{ width: "88%" }}
												></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="bg-white rounded-xl shadow-lg p-8">
							<h2 className="text-2xl font-bold mb-6">Optimization Process</h2>
							<div className="relative">
								<div className="absolute top-0 left-6 h-full w-0.5 bg-green-200"></div>
								<div className="space-y-8 relative">
									<div className="flex gap-6">
										<div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center z-10">
											<span className="material-symbols-outlined text-green-600">
												upload_file
											</span>
										</div>
										<div>
											<h3 className="font-semibold">Upload Résumé</h3>
											<p className="text-gray-600">
												Submit your current résumé for analysis
											</p>
										</div>
									</div>
									<div className="flex gap-6">
										<div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center z-10">
											<span className="material-symbols-outlined text-green-600">
												analytics
											</span>
										</div>
										<div>
											<h3 className="font-semibold">AI Analysis</h3>
											<p className="text-gray-600">
												Deep scan of content and structure
											</p>
										</div>
									</div>
									<div className="flex gap-6">
										<div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center z-10">
											<span className="material-symbols-outlined text-green-600">
												psychology
											</span>
										</div>
										<div>
											<h3 className="font-semibold">Build Mental Maps</h3>
											<p className="text-gray-600">
												Conceptualize and draft detailed intelligent suggestions
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="bg-green-600 rounded-xl shadow-lg p-8 text-center text-white">
							<h2 className="text-3xl font-bold mb-4">
								Process Your Résumé Now
							</h2>
							<p className="text-xl mb-6">
								Submit your personalized résumé for analysis
							</p>
							<FreeTrialButton className="feature-free-trial-button resume-free-trial-button glint-link">
								Analyze Résumé
							</FreeTrialButton>
						</div>
					</div>
				</main>
			</div>
		</>
	);
};

export default ResumePage;
