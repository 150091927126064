import {
	DetailedHTMLProps,
	FC,
	HTMLAttributes,
	useContext,
	useEffect
} from "react";
import { AuthContext, AuthContextType } from "../context/AuthProvider";
import { User } from "firebase/auth";

declare global {
	namespace JSX {
		interface IntrinsicElements {
			"stripe-pricing-table": DetailedHTMLProps<
				HTMLAttributes<HTMLElement>,
				HTMLElement
			>;
		}
	}
}

// Created using https://docs.stripe.com/payments/checkout/pricing-table

const StripePricingTable: FC = () => {
	// Assume this is being used within an AuthProvider
	const authContext = useContext(AuthContext) as AuthContextType;
	// Assume the user is authenticated
	const user = authContext.user as User;

	useEffect(() => {
		const script = document.createElement("script");
		script.async = true;
		script.src = "https://js.stripe.com/v3/pricing-table.js";
		document.head.appendChild(script);
		// Remove script on component unmount
		return () => {
			document.head.removeChild(script);
		};
	}, []);

	return (
		<stripe-pricing-table
			pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
			publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
			client-reference-id={user.uid}
			customer-email={user.email}
		></stripe-pricing-table>
	);
};

export default StripePricingTable;
