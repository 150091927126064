import React, { useContext, useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

// Import types for dnd
import type { DroppableId, DropResult } from "@hello-pangea/dnd";

// Local imports
import "./index.css";
import AddJobModal from "./addJobModal/addJobModal";
import DNDCard from "./DNDCard";
import {
	API_HTTP,
	resumeAnalyzerApiEndpoints
} from "./services/api-client/endpoints";
import ApiInterface from "./services/api-client/api-interface";
import Navbar from "./components/navbar";
import { AuthContext, AuthContextType } from "./context/AuthProvider";
import { User } from "firebase/auth";
import LoadingWheel from "./components/LoadingWheel";
import SwimlanesDirections from "./components/SwimlanesDirections";
import SwimlanesContext from "./context/SwimlanesContext";
import AddJobButton from "./components/AddJobButton";

const APPLIED = "applied";
const CONFIRMED = "confirmed";
const RECRUITERINTERVIEW = "recruiterinterview";
const HIRINGMANAGERINTERVIEW = "hiringmanagerinterview";
const DONE = "done";

const JOB_STATE_CHANGE_TIMEOUT_MS = 4000;

const MAX_FREE_TRIAL_JOBS = 2;

const UNLIMITED_JOBS_PERMISSION = "features/unlimited-jobs";

type JobState =
	| typeof APPLIED
	| typeof CONFIRMED
	| typeof RECRUITERINTERVIEW
	| typeof HIRINGMANAGERINTERVIEW
	| typeof DONE;

interface JobPostingData {
	id: string;
	title: string;
	jobDescription: string;
	creationTimestamp: Date;
	company: string;
	state: JobState;
	order: number;
}

type JobStateChange = {
	jobId: number;
	newState: string;
	newOrder: number;
};

interface ColumnProps {
	droppableId: DroppableId;
	title: string;
	count: number;
	button?: React.JSX.Element;
	children?: React.ReactNode;
}

function DNDColumn({
	droppableId,
	title,
	count,
	button,
	children
}: ColumnProps): React.ReactElement {
	return (
		<div className="swimlane">
			<div className="colheader">
				<h3>{title}</h3>
				<div className="colheader-container">
					{button}
					<div className="cardcount">{count}</div>
				</div>
			</div>
			<Droppable droppableId={droppableId}>
				{(provided, snapshot) => (
					<div
						className={[
							"swimlane-droparea",
							snapshot.isDraggingOver ? " swimlane-droparea-draggingover" : ""
						].join("")}
						{...provided.droppableProps}
						ref={provided.innerRef}
					>
						{children}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</div>
	);
}

function DoneColumn(): React.ReactElement {
	return (
		<Droppable droppableId={DONE}>
			{(provided, snapshot) => (
				<div
					className={`swimlane-done${snapshot.isDraggingOver ? " swimlane-done-draggingover" : ""}`}
				>
					<div className="swimlane-done-icon">
						<span className="material-symbols-outlined">scan_delete</span>
					</div>
					<div
						className="swimlane-done-droparea"
						{...provided.droppableProps}
						ref={provided.innerRef}
					>
						{provided.placeholder}
					</div>
				</div>
			)}
		</Droppable>
	);
}

interface SwimlanesState {
	[APPLIED]: JobPostingData[];
	[CONFIRMED]: JobPostingData[];
	[RECRUITERINTERVIEW]: JobPostingData[];
	[HIRINGMANAGERINTERVIEW]: JobPostingData[];
	[key: string]: JobPostingData[];
}

function Swimlanes() {
	// Assume this is being used within an AuthProvider
	const authContext = useContext(AuthContext) as AuthContextType;
	// Assume the user is authenticated
	const user = authContext.user as User;

	const queryClient = useQueryClient();

	// State

	const [postings, setPostings] = useState<SwimlanesState>({
		[APPLIED]: [],
		[CONFIRMED]: [],
		[RECRUITERINTERVIEW]: [],
		[HIRINGMANAGERINTERVIEW]: []
	});

	const [doneJobsCount, setDoneJobsCount] = useState<number>(0);

	const {
		isLoading: jobsQueryLoading,
		data: jobsQueryData,
		error: jobsQueryError,
		isSuccess: jobsQuerySuccess
	} = useQuery({
		queryKey: ["user", user.uid, "jobs"],
		queryFn: () => {
			const apiClient = new ApiInterface(API_HTTP.resumeAnalyzer, user);
			return apiClient.get(resumeAnalyzerApiEndpoints.jobs);
		}
	});

	/*
		Get the user's cached permissions.
		Because this page is behind a PrivateRouteWrapper which calls the
		permissions endpoint every time, these are basically guaranteed to always
		be present and up-to-date.
	*/
	const userPermissions: string[] =
		queryClient.getQueryData(["user", user.uid, "permissions"]) ?? [];

	// Check if the user has permission to create unlimited jobs
	const userHasUnlimitedJobsPermission = userPermissions.includes(
		UNLIMITED_JOBS_PERMISSION
	);

	// Determine how many free jobs the user has left to create, if applicable
	const totalJobsCount = Object.values(postings).flat().length + doneJobsCount;
	const freeJobsRemaining =
		!userHasUnlimitedJobsPermission && jobsQuerySuccess
			? Math.max(MAX_FREE_TRIAL_JOBS - totalJobsCount, 0)
			: Infinity;

	// Check if the user has a resume so we know whether to enable the add job button
	const { data: resumeQueryData } = useQuery<{ resume: string | null }>({
		queryKey: ["user", user.uid, "resume"],
		enabled: false
	});
	const cachedResumeFound = !!resumeQueryData?.resume;

	const [stateChangeTimeoutId, setStateChangeTimeoutId] = useState<
		NodeJS.Timeout | undefined
	>();
	const [stateChangeQueue, setStateChangeQueue] = useState<{
		waiting: JobStateChange[];
		sent: JobStateChange[];
	}>({ waiting: [], sent: [] });

	const stateChangeTimeoutIdRef = useRef(stateChangeTimeoutId);
	const stateChangeQueueRef = useRef(stateChangeQueue);

	const jobStateMutation = useMutation({
		mutationFn: (stateChanges: JobStateChange[]): Promise<any> => {
			const apiClient = new ApiInterface(API_HTTP.resumeAnalyzer, user);
			return apiClient.patch(resumeAnalyzerApiEndpoints.jobs, { stateChanges });
		},
		onSuccess: (): void => {
			// Clear the sent queue
			const newStateChangeQueue = {
				...stateChangeQueueRef.current,
				sent: []
			};
			setStateChangeQueue(newStateChangeQueue);
			// Update ref for onSettled callback
			stateChangeQueueRef.current = newStateChangeQueue;
		},
		onSettled: (): void => {
			// If there are waiting state changes with no timeout, trigger another mutation
			if (
				!stateChangeTimeoutIdRef.current &&
				stateChangeQueueRef.current.waiting.length
			) {
				doJobStateMutation();
			}
		}
	});

	const jobStateMutationStatusRef = useRef<
		"error" | "idle" | "pending" | "success"
	>(jobStateMutation.status);

	// Effects

	useEffect(() => {
		// Update reference to status of jobStateMutation
		jobStateMutationStatusRef.current = jobStateMutation.status;
	}, [jobStateMutation.status]);

	useEffect(() => {
		// Update reference to the state change queue
		stateChangeQueueRef.current = stateChangeQueue;
	}, [stateChangeQueue]);

	useEffect(() => {
		// Update reference to the state change timeout ID
		stateChangeTimeoutIdRef.current = stateChangeTimeoutId;
	}, [stateChangeTimeoutId]);

	useEffect(() => {
		let jobs: JobPostingData[] = [];

		if (jobsQueryError) {
			console.error(jobsQueryError);
			setTimeout(() => alert("The API could not be reached"), 100);
		}

		if (jobsQueryData) {
			// Convert to the correct type
			jobs = jobsQueryData.jobs.map((job: any) => {
				return {
					id: `${job.jobId}`,
					state: job.state,
					title: job.title,
					company: job.company,
					order: job.order,
					jobDescription: "",
					creationTimestamp: new Date(job.creationTimestamp)
				};
			});
			// Update done jobs count state
			setDoneJobsCount(jobsQueryData.doneJobs);
		}

		// Sort jobs into the correct columns
		// Use reduce() here because this version of TypeScript doesn't have groupBy()
		// See https://github.com/microsoft/TypeScript/issues/47171
		const swimlanesState = jobs.reduce(
			(stateObj, job) => {
				stateObj[job.state]?.push(job) ?? (stateObj[job.state] = [job]);
				return stateObj;
			},
			{
				[APPLIED]: [],
				[CONFIRMED]: [],
				[RECRUITERINTERVIEW]: [],
				[HIRINGMANAGERINTERVIEW]: []
			} as SwimlanesState
		);

		// Sort the contents of each swimlane column by the job posting "order" property
		for (let column in swimlanesState) {
			swimlanesState[column].sort((jobA, jobB) => jobA.order - jobB.order);
		}

		// Update swimlanes jobs state
		setPostings(swimlanesState);
	}, [jobsQueryData, jobsQueryError]);

	const [isAddJobModalOpen, setAddJobModalOpen] = useState<boolean>(false);

	// Functions

	const enqueueJobStateChange = (stateChange: JobStateChange): void => {
		// Add a state change to the waiting queue
		setStateChangeQueue((queue) => ({
			...queue,
			waiting: [...queue.waiting, stateChange]
		}));
		// Clear any existing previous timeout
		if (stateChangeTimeoutId) {
			clearTimeout(stateChangeTimeoutId);
		}
		const stateChangeCallback = () => {
			// Check for pending requests and trigger a mutation if there are none
			if (jobStateMutationStatusRef.current !== "pending") {
				doJobStateMutation();
			}
			// Delete the timeout ID
			setStateChangeTimeoutId(undefined);
		};
		// Set timeout for triggering mutation
		setStateChangeTimeoutId(
			setTimeout(stateChangeCallback, JOB_STATE_CHANGE_TIMEOUT_MS)
		);
	};

	const doJobStateMutation = (): void => {
		const { sent, waiting } = stateChangeQueueRef.current;
		// Transfer waiting queue into sent queue
		const mutationData = [...sent, ...waiting];
		setStateChangeQueue({ waiting: [], sent: mutationData });
		// Trigger mutation
		jobStateMutation.mutate(mutationData);
	};

	const findJobPosting = (jobId: string): JobPostingData | undefined => {
		return Object.values(postings)
			.flat()
			.find((job) => job.id === jobId);
	};

	const moveJobToDone = (jobId: string): void => {
		const jobPosting = findJobPosting(jobId);
		if (jobPosting) {
			moveJob(jobPosting, jobPosting.state, DONE);
		}
	};

	const onDragEnd = (result: DropResult) => {
		const { source, destination } = result;
		// Skip if job was dropped back in place or outside the drop area
		if (
			destination &&
			(destination.droppableId !== source.droppableId ||
				destination.index !== source.index)
		) {
			moveJob(
				postings[source.droppableId][source.index],
				source.droppableId as JobState,
				destination.droppableId as JobState,
				destination.index
			);
		}
	};

	const moveJob = (
		jobPosting: JobPostingData,
		fromState: JobState,
		toState: JobState,
		toOrder: number = 0
	): void => {
		// Create a copy of the source column
		const sourceColumnCopy = [...postings[fromState]];
		// Remove the job from the copy
		sourceColumnCopy.splice(jobPosting.order, 1);
		// Update the order property of jobs in the copy to match the actual order
		sourceColumnCopy.forEach((job, index) => (job.order = index));
		// Track which columns have been changed
		const affectedColumns = { [fromState]: sourceColumnCopy };
		// Skip updating the destination column if job is moved to "done"
		if (toState !== DONE) {
			const noStateChange = toState === fromState;
			// Create a copy of the destination column (if applicable)
			const destinationColumnCopy = noStateChange
				? sourceColumnCopy
				: [...postings[toState]];
			// Insert the job into the copy
			destinationColumnCopy.splice(toOrder, 0, jobPosting);
			// Update the order property of jobs in the copy to match the actual order
			destinationColumnCopy.forEach((job, index) => (job.order = index));
			// Track that this column has been changed too
			affectedColumns[toState] = destinationColumnCopy;
		}
		// Set the new state and order of the job
		jobPosting.state = toState;
		jobPosting.order = toOrder;
		// Update Swimlanes component state, replacing columns with updated copies
		setPostings({
			...postings,
			...affectedColumns
		});
		// If the job was moved to "done", increment the number of done jobs
		if (toState === DONE) {
			setDoneJobsCount((count) => ++count);
		}
		// Optimistic cache update
		queryClient.setQueryData(
			["user", user.uid, "jobs"],
			({
				jobs: cachedJobs,
				doneJobs
			}: {
				jobs: any[];
				doneJobs: number;
			}): { jobs: any[]; doneJobs: number } => {
				// Map IDs to job postings
				const jobs = new Map<number, JobPostingData>(
					Object.values(postings)
						.flat()
						.map((job) => [parseInt(job.id), job])
				);
				// Update state and order of each job in the cache
				cachedJobs.forEach((cachedJob) => {
					const job = jobs.get(cachedJob.jobId);
					if (job) {
						cachedJob.state = job.state;
						cachedJob.order = job.order;
					}
				});
				// If the job was moved to "done", increment the doneJobs number in the cache
				if (toState === DONE) {
					++doneJobs;
				}
				return { jobs: cachedJobs, doneJobs };
			}
		);
		// Queue up an API call to change the job state
		enqueueJobStateChange({
			jobId: parseInt(jobPosting.id),
			newState: jobPosting.state,
			newOrder: jobPosting.order
		});
	};

	// Utility function to create the tooltip for the add job button
	const buildAddJobButtonTooltip = (): string => {
		if (!cachedResumeFound) {
			return resumeQueryData ? "Upload a resume first!" : "Loading resume...";
		} else if (freeJobsRemaining < Infinity) {
			return `${freeJobsRemaining} free jobs remaining`;
		} else {
			return "";
		}
	};

	// Update open/closed state of AddJobModal
	const handleOpenAddJobModal = () => {
		setAddJobModalOpen(true);
	};

	const handleCloseAddJobModal = () => {
		setAddJobModalOpen(false);
	};

	const populateCards = (jobPostings: JobPostingData[]) => {
		return jobPostings.map((posting, index) => (
			<DNDCard key={posting.id} posting={posting} index={index} />
		));
	};

	return (
		<SwimlanesContext.Provider value={{ moveJobToDone }}>
			<DragDropContext onDragEnd={onDragEnd}>
				<DNDColumn
					title="Applied"
					droppableId={APPLIED}
					count={postings[APPLIED].length}
					button={
						<AddJobButton
							handleClick={handleOpenAddJobModal}
							disabled={
								jobsQueryLoading || !!jobsQueryError || !cachedResumeFound
							}
							tooltip={buildAddJobButtonTooltip()}
							freeJobsRemaining={freeJobsRemaining}
						/>
					}
				>
					{jobsQueryLoading ? (
						<LoadingWheel
							svgClasses={["text-gray-200", "fill-gray-300", "w-9"]}
						/>
					) : (
						populateCards(postings[APPLIED])
					)}
				</DNDColumn>
				<DNDColumn
					title="Confirmed"
					droppableId={CONFIRMED}
					count={postings[CONFIRMED].length}
				>
					{jobsQueryLoading ? (
						<LoadingWheel
							svgClasses={["text-gray-200", "fill-gray-300", "w-9"]}
						/>
					) : (
						populateCards(postings[CONFIRMED])
					)}
				</DNDColumn>
				<DNDColumn
					title="Recruiter Interview"
					droppableId={RECRUITERINTERVIEW}
					count={postings[RECRUITERINTERVIEW].length}
				>
					{jobsQueryLoading ? (
						<LoadingWheel
							svgClasses={["text-gray-200", "fill-gray-300", "w-9"]}
						/>
					) : (
						populateCards(postings[RECRUITERINTERVIEW])
					)}
				</DNDColumn>
				<DNDColumn
					title="Hiring Manager Interview"
					droppableId={HIRINGMANAGERINTERVIEW}
					count={postings[HIRINGMANAGERINTERVIEW].length}
				>
					{jobsQueryLoading ? (
						<LoadingWheel
							svgClasses={["text-gray-200", "fill-gray-300", "w-9"]}
						/>
					) : (
						populateCards(postings[HIRINGMANAGERINTERVIEW])
					)}
				</DNDColumn>
				<DoneColumn />
				<AddJobModal
					isOpen={isAddJobModalOpen}
					onClose={handleCloseAddJobModal}
					showUpgradeMessage={
						totalJobsCount > 0 && freeJobsRemaining < Infinity
					}
					hideForm={freeJobsRemaining === 0}
				/>
			</DragDropContext>
		</SwimlanesContext.Provider>
	);
}

function SwimlanesPage() {
	return (
		<React.StrictMode>
			<Navbar />
			<SwimlanesDirections />
			<main className="max-w-7xl mx-auto grid justify-items-stretch justify-content-center">
				<div className="flex flex-row flex-nowrap gap-5 justify-center py-12">
					<Swimlanes />
				</div>
			</main>
		</React.StrictMode>
	);
}

export { APPLIED, CONFIRMED, RECRUITERINTERVIEW, HIRINGMANAGERINTERVIEW };

export type { JobPostingData, JobState };

export default SwimlanesPage;
