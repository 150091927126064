import { FC, useContext, useEffect } from "react";
import { AuthContext, AuthContextType } from "../context/AuthProvider";
import { Navigate, Outlet } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import ApiInterface from "../services/api-client/api-interface";
import {
	API_HTTP,
	resumeAnalyzerApiEndpoints
} from "../services/api-client/endpoints";
import { toast } from "react-toastify";

const AuthenticationPage: FC = () => (
	<div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
		{/* Modified from an SVG generated on icons8.com */}
		<svg
			xmlns="http://www.w3.org/2000/svg"
			version="1.0"
			width="96px"
			height="96px"
			viewBox="0 0 128 128"
		>
			<g>
				<path
					fill="#575757"
					d="M116.7 66.28a52.86 52.86 0 0 1-1 8.18l9.8 7.57-1.78 4.67-1.77 4.67-12.36-.82a52.87 52.87 0 0 1-4.7 6.7L110 108.5l-3.75 3.3-3.75 3.32L92 108.67a52.6 52.6 0 0 1-7.45 3.9l-.66 12.3-4.87 1.2-4.86 1.2-6.38-10.66q-1.9.2-3.88.2-2.15 0-4.25-.14l-6.3 10.64-4.84-1.2-4.85-1.2-.7-12.43a52.6 52.6 0 0 1-6.7-3.5l-10.6 6.64-3.75-3.3-3.76-3.3 5.05-11.4a52.88 52.88 0 0 1-4.73-6.73l-12.34.9-1.8-4.66-1.8-4.67 9.7-7.67a52.8 52.8 0 0 1-1-8.05l-11.4-5 .56-4.95.54-4.97 12.26-2.3a52.37 52.37 0 0 1 2.94-7.83L8.4 32l2.8-4.14 2.8-4.14 12 3.68a53.06 53.06 0 0 1 6-5.33L29.57 9.8l4.4-2.37 4.43-2.35 8.95 8.86a52.4 52.4 0 0 1 8-1.98L59 0h10l3.66 11.96a52.4 52.4 0 0 1 7.8 1.9L89.26 5l4.42 2.3 4.43 2.34-2.3 12.27a52.98 52.98 0 0 1 6.2 5.5l11.9-3.7 2.9 4.1 2.84 4.1-7.8 9.8a52.34 52.34 0 0 1 2.86 7.5l12.3 2.17.6 4.96.57 4.95zM41 64a23 23 0 1 0 23-23 23 23 0 0 0-23 23z"
				/>
				<animateTransform
					attributeName="transform"
					type="rotate"
					from="0 64 64"
					to="27.69 64 64"
					dur="600ms"
					repeatCount="indefinite"
				></animateTransform>
			</g>
		</svg>
		<p className="absolute font-light left-1/2 text-3xl -translate-x-1/2 translate-y-4">
			Authenticating
		</p>
	</div>
);

interface PrivateRouteWrapperProps {
	routePermission?: string;
	fallbackPath?: string;
}

const PrivateRouteWrapper = ({
	routePermission,
	fallbackPath = "/newlanding"
}: PrivateRouteWrapperProps) => {
	// Assume this is being used within an AuthProvider
	const { loading: authLoading, user } = useContext(
		AuthContext
	) as AuthContextType;
	const {
		isLoading: queryLoading,
		data: permissions,
		isError: isQueryError,
		isFetchedAfterMount
	} = useQuery({
		queryKey: ["user", user?.uid, "permissions"],
		queryFn: () => {
			if (user?.uid) {
				// TODO: Investigate what causes user.uid to be "0"
				// 	Is this working as intended?
				const apiClient = new ApiInterface(API_HTTP.resumeAnalyzer, user);
				return apiClient
					.get(resumeAnalyzerApiEndpoints.permissions)
					.then((data) => data.permissions)
					.catch((err) => {
						// A 403 error is equivalent to having no permissions
						if (err.status === 403) {
							return [];
						} else {
							throw err;
						}
					});
			} else {
				return [];
			}
		}
	});

	useEffect(() => {
		if (isQueryError) {
			toast.error(
				<span>
					An error occurred during authentication.
					<br />
					Please check your network connection and reload the page.
				</span>,
				{
					containerId: "toast-main",
					toastId: "permissions-error",
					position: "top-center",
					theme: "colored",
					autoClose: false
				}
			);
		}
	}, [isQueryError]);

	// Checking isFetchedAfterMount forces the component to always use fresh data
	if (authLoading || queryLoading || isQueryError || !isFetchedAfterMount) {
		return <AuthenticationPage />;
	} else if (!user) {
		// Redirect to login page if user is not authenticated
		return <Navigate to="/login" />;
	} else if (routePermission && !permissions?.includes(routePermission)) {
		// Redirect to the fallback path (default is "newlanding") if user has insufficient permissions
		return <Navigate to={fallbackPath} />;
	} else {
		// Render the route(s)
		return <Outlet />;
	}
};

export default PrivateRouteWrapper;
