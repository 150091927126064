// React imports
import { FC } from "react";
import { Link } from "react-router-dom";

interface UpgradeButtonProps {
	className?: string;
}

const UpgradeButton: FC<UpgradeButtonProps> = ({ className = "" }) => {
	return (
		<Link
			to="/pricing"
			className={`bg-green-500 font-semibold pt-1 pb-3 px-4 rounded text-3xl text-white ${className}`}
		>
			Upgrade
		</Link>
	);
};

export default UpgradeButton;
