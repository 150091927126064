// React imports
import { FC, useContext, useEffect } from "react";

// Library imports
import { useMutation } from "@tanstack/react-query";
import { Navigate, useNavigate } from "react-router-dom";
import { User } from "firebase/auth";

// Local imports
import {
	API_HTTP,
	resumeAnalyzerApiEndpoints
} from "../services/api-client/endpoints";
import ApiInterface from "../services/api-client/api-interface";
import { AuthContext, AuthContextType } from "../context/AuthProvider";
import { AxiosError } from "axios";
import SupportEmailAddressLink from "../components/SupportEmailAddressLink";
import { toast } from "react-toastify";

const ActivationPage: FC = () => {
	// Assume this is being used within an AuthProvider
	const authContext = useContext(AuthContext) as AuthContextType;
	// Assume the user is authenticated
	const user = authContext.user as User;

	// Get checkout session ID from search params
	const params = new URLSearchParams(window.location.search);
	const checkoutSessionId = params.get("session_id");

	const navigate = useNavigate();

	const activationMutation = useMutation({
		mutationFn: async (checkoutSessionId: string): Promise<void> => {
			const apiClient = new ApiInterface(API_HTTP.resumeAnalyzer, user);
			return apiClient.post(resumeAnalyzerApiEndpoints.orders, {
				action: "activate-subscription",
				checkoutSessionId
			});
		}
	});

	const showActivationErrorToast = (): void => {
		toast.error(
			<div className="px-2 text-center">
				<b>Something went wrong!</b>
				<p>Please refresh and try again.</p>
				<p>
					Contact <SupportEmailAddressLink /> if the problem persists.
				</p>
			</div>,
			{
				containerId: "toast-main",
				position: "top-center",
				theme: "colored",
				autoClose: false,
				hideProgressBar: true,
				draggable: false
			}
		);
	};

	const showActivationSuccessToast = (duration: number): void => {
		toast.success(
			<p className="text-center">
				<b>Activation was successful!</b>
				<br />
				You will be redirected shortly
			</p>,
			{
				containerId: "toast-main",
				position: "top-center",
				theme: "colored",
				autoClose: duration,
				hideProgressBar: false,
				draggable: false
			}
		);
	};

	useEffect(() => {
		//If there is a checkout session ID, send an activation request to the API
		if (checkoutSessionId && activationMutation.isIdle) {
			activationMutation.mutate(checkoutSessionId, {
				onSettled: (_, error) => {
					if (error) {
						/*
							A 400 BAD REQUEST is probably because the checkout session ID is invalid.
							Therefore proceed directly to the swimlanes page.
						*/
						if (error instanceof AxiosError && error.status === 400) {
							navigate("/dnd");
						} else {
							showActivationErrorToast();
						}
					} else {
						const toastDuration = 5000;
						showActivationSuccessToast(toastDuration);
						setTimeout(() => navigate("/dnd"), toastDuration);
					}
				}
			});
		}
	}, [activationMutation, navigate, checkoutSessionId]);

	return checkoutSessionId ? (
		<div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
			{/* Modified from an SVG generated on icons8.com */}
			<svg
				className="animate-[spin_1.75s_linear_infinite]"
				xmlns="http://www.w3.org/2000/svg"
				version="1.0"
				width="128px"
				height="128px"
				viewBox="0 0 128 128"
			>
				<g>
					<path
						d="M62.77 23.65v7.95L90.2 16.1 62.37.2l.4 8.35s-29.52 1.54-46.1 27.43c-18.98 29.6.4 58.03.4 58.03S2.74 63 24.2 39.97c18.4-19.75 38.57-16.3 38.57-16.3zm2.03 80.9V96.6l-27.44 15.5L65.2 128l-.4-8.35s29.53-1.54 46.13-27.43c18.98-29.6-.4-58.03-.4-58.03s14.3 31-7.16 54.04c-18.4 19.75-38.57 16.3-38.57 16.3z"
						fill="#1ccd67"
						fillOpacity="1"
					/>
				</g>
			</svg>
			<p className="absolute font-light left-1/2 text-3xl -translate-x-1/2 translate-y-2">
				Activating
			</p>
		</div>
	) : (
		// Redirect to the pricing page if no checkout session ID is present
		<Navigate to="/pricing" />
	);
};

export default ActivationPage;
