import React, { ReactNode } from "react";

import "./JobSearchFeature.css";

// export default JobSearchFeature;
interface FeatureProps {
	title?: string;
	description?: string; // Made optional since we now have children
	imageSrc: string;
	isLeftHanded: boolean;
	children?: ReactNode; // Add children prop
}

const JobSearchFeature: React.FC<FeatureProps> = ({
	title,
	description,
	imageSrc,
	isLeftHanded,
	children
}) => {
	return (
		<div
			className={`feature-container ${isLeftHanded ? "left-handed" : "right-handed"}`}
		>
			<div className="image-container">
				<img src={imageSrc} alt={title} className="feature-image" />
			</div>
			<div className="content-container">
				{title && <h2 className="feature-title">{title}</h2>}
				{description && <p className="feature-description">{description}</p>}
				{children} {/* Render children below the description */}
			</div>
		</div>
	);
};

export default JobSearchFeature;
