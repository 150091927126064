import React from "react";
import CustomizeIcon from "../../components/Icons/CustomizeIcon";
import Hotjar from "../../components/Hotjar";
import FreeTrialButton from "../../components/FreeTrialButton";

const CustomizationPage = () => {
	return (
		<>
			<Hotjar />
			<div className="min-h-screen bg-gray-50">
				<nav className="bg-white shadow sticky top-0">
					<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
						<div className="flex justify-between h-16">
							<div className="flex items-center">
								<CustomizeIcon />
								<span className="ml-2 text-xl font-semibold">
									Manual Customization
								</span>
							</div>
						</div>
					</div>
				</nav>

				<main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
					<div className="space-y-12">
						<div className="text-center">
							<h1 className="text-4xl font-bold text-gray-900 mb-4">
								Take Full Control of Your Content
							</h1>
							<p className="text-xl text-gray-600 max-w-2xl mx-auto">
								Fine-tune every detail with our powerful editing tools.
							</p>
						</div>

						<div className="grid md:grid-cols-2 gap-8">
							<div className="bg-white rounded-xl shadow-lg p-8">
								<h2 className="text-2xl font-bold mb-6">Edit Everything</h2>
								<div className="space-y-6">
									<div className="flex items-start gap-4">
										<div className="w-10 h-10 bg-amber-100 rounded-lg flex items-center justify-center">
											<span className="material-symbols-outlined text-amber-600">
												format_paint
											</span>
										</div>
										<div>
											<h3 className="font-semibold">Formatting Controls</h3>
											<p className="text-gray-600">
												Adjust fonts, spacing, and layout to match your style
											</p>
										</div>
									</div>
									<div className="flex items-start gap-4">
										<div className="w-10 h-10 bg-amber-100 rounded-lg flex items-center justify-center">
											<span className="material-symbols-outlined text-amber-600">
												palette
											</span>
										</div>
										<div>
											<h3 className="font-semibold">Style Options</h3>
											<p className="text-gray-600">
												Choose from various templates and color schemes
											</p>
										</div>
									</div>
									<div className="flex items-start gap-4">
										<div className="w-10 h-10 bg-amber-100 rounded-lg flex items-center justify-center">
											<span className="material-symbols-outlined text-amber-600">
												history
											</span>
										</div>
										<div>
											<h3 className="font-semibold">Version History</h3>
											<p className="text-gray-600">
												Track changes and revert to previous versions
											</p>
										</div>
									</div>
								</div>
							</div>

							<div className="bg-white rounded-xl shadow-lg p-8">
								<h2 className="text-2xl font-bold mb-6">Live Preview</h2>
								<div className="border rounded-lg p-4 bg-gray-50">
									<div className="space-y-4">
										<div className="h-6 bg-gray-200 rounded w-3/4"></div>
										<div className="h-4 bg-gray-200 rounded w-full"></div>
										<div className="h-4 bg-gray-200 rounded w-5/6"></div>
										<div className="h-4 bg-gray-200 rounded w-4/5"></div>
									</div>
									<div className="mt-4 flex gap-2">
										<button className="px-3 py-1 bg-amber-100 text-amber-600 rounded text-sm">
											Format
										</button>
										<button className="px-3 py-1 bg-amber-100 text-amber-600 rounded text-sm">
											Edit
										</button>
										<button className="px-3 py-1 bg-amber-100 text-amber-600 rounded text-sm">
											Preview
										</button>
									</div>
								</div>
							</div>
						</div>

						<div className="bg-white rounded-xl shadow-lg p-8">
							<h2 className="text-2xl font-bold mb-6">Export Options</h2>
							{/* <div className="grid md:grid-cols-3 gap-6"> */}
							<div className="grid md:grid-cols-2 gap-6">
								<div className="p-6 border rounded-lg">
									<span className="material-symbols-outlined text-3xl text-amber-600 mb-4">
										picture_as_pdf
									</span>
									<h3 className="font-semibold mb-2">PDF Export</h3>
									<p className="text-gray-600">
										Professional quality PDFs ready for sharing
									</p>
								</div>
								<div className="p-6 border rounded-lg">
									<span className="material-symbols-outlined text-3xl text-amber-600 mb-4">
										cloud_download
									</span>
									<h3 className="font-semibold mb-2">Plain Text</h3>
									<p className="text-gray-600">
										Simple text format for maximum compatibility
									</p>
								</div>
							</div>
						</div>

						<div className="bg-amber-600 rounded-xl shadow-lg p-8 text-center text-white">
							<h2 className="text-3xl font-bold mb-4">Make It Your Own</h2>
							<p className="text-xl mb-6">
								Start customizing your documents today
							</p>
							<FreeTrialButton className="customization-free-trial-button feature-free-trial-button glint-link">
								Start Editing
							</FreeTrialButton>
						</div>
					</div>
				</main>
			</div>
		</>
	);
};

export default CustomizationPage;
