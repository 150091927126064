// React imports
import { FC } from "react";

interface AddJobButtonProps {
	handleClick: () => void;
	disabled?: boolean;
	tooltip?: string;
	freeJobsRemaining?: number;
}

const AddJobButton: FC<AddJobButtonProps> = ({
	handleClick,
	disabled,
	tooltip,
	freeJobsRemaining = Infinity
}) => {
	return (
		<div className="relative">
			{freeJobsRemaining !== Infinity && (
				<span className="absolute bg-yellow-300 leading-snug left-1/2 rounded text-black text-center text-xs -top-5 -translate-x-1/2 w-[4.5rem]">
					{freeJobsRemaining} remaining
				</span>
			)}
			<button
				className="add-job-button"
				disabled={disabled}
				onClick={handleClick}
				title={tooltip}
			>
				<span className="material-symbols-outlined">add</span>
			</button>
		</div>
	);
};

export default AddJobButton;
