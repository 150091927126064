import { ButtonWithTooltip } from "@mdxeditor/editor";
import { useState } from "react";
import { GetRemainingRegenerations } from "../../../localStorage/regenAi";

//Example: https://github.com/facebook/lexical/blob/main/examples/react-rich/src/plugins/ToolbarPlugin.tsx

interface RedoAiProps {
	onClick?: () => void;
	userId: string;
	disabled?: boolean;
}

//TODO: can we encapsolate regenAi.tsx in within this file?

/**
 * Enables user to regenerate the contents of the MDXEditor with new content from the AI
 * There is a limit per user.
 * @param {RedoAiProps} props - Props.
 * @param {function} props.onClick - onClick function attribute for the button.
 * @param {boolean} props.disabled - disabled attribute for the button.
 */
function RedoAi({ onClick, userId, disabled = false }: RedoAiProps) {
	const [remaining] = useState<number>(GetRemainingRegenerations(userId)); // Initialize state
	disabled = disabled && remaining > 0;
	//When this button is pushed, it should track the "failure" of the prompt that generated this Text, as the user feels this is not sufficent

	return (
		<ButtonWithTooltip
			className="redoAi"
			title="Regenerate"
			onClick={onClick}
			disabled={disabled}
		>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				{/* Main pencil shape */}
				<path
					d="M16 4L19 7L14 12L12 14L9 17L5 18L6 14L9 11L11 9L16 4Z"
					fill="currentColor"
				/>

				{/* 4-pointed star sparkles */}
				<path
					d="M2 17L3 16L4 17L3 18L2 17ZM3 16L3 15L3 16ZM3 18L3 19L3 18ZM2 17L1 17L2 17ZM4 17L5 17L4 17Z"
					fill="yellow"
				/>
				<path
					d="M7 15L8 14L9 15L8 16L7 15ZM8 14L8 13L8 14ZM8 16L8 17L8 16ZM7 15L6 15L7 15ZM9 15L10 15L9 15Z"
					fill="yellow"
				/>
				<path
					d="M3 13L4 12L5 13L4 14L3 13ZM4 12L4 11L4 12ZM4 14L4 15L4 14ZM3 13L2 13L3 13ZM5 13L6 13L5 13Z"
					fill="yellow"
				/>

				{/* Circled number badge */}
				<circle
					cx="19"
					cy="19"
					r="7"
					fill={remaining === 0 ? "red" : "green"}
				/>
				<text
					x="19"
					y="22.5"
					textAnchor="middle"
					fontSize="11"
					fill="white"
					fontFamily="sans-serif"
					fontWeight="bold"
				>
					{remaining}
				</text>

				<rect x="0" y="0" width="24" height="24" fillOpacity="0" />
			</svg>
		</ButtonWithTooltip>
	);
}

export default RedoAi;
