// React imports
import { useContext, useState } from "react";

// Library imports
import type { User } from "firebase/auth";
import {
	ContextMenuTrigger,
	ContextMenu,
	ContextMenuItem
} from "rctx-contextmenu";
import { convert as convertHtmlToText } from "html-to-text";
import { Draggable } from "@hello-pangea/dnd";
import { marked } from "marked";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

// Local imports
import type { AuthContextType } from "./context/AuthProvider";
import type { JobPostingData } from "./Swimlanes";
import { API_HTTP } from "./services/api-client/endpoints";
import ApiInterface from "./services/api-client/api-interface";
import { AuthContext } from "./context/AuthProvider";
import CardDurationBadge from "./components/CardDurationBadge";
import JobDetailsModal from "./JobDetailsModal";
import { resumeAnalyzerApiEndpoints } from "./services/api-client/endpoints";
import SwimlanesContext from "./context/SwimlanesContext";

interface CardProps {
	posting: JobPostingData;
	index: number;
}

function DNDCard({ posting, index }: CardProps): React.ReactElement {
	// Assume this is being used within an AuthProvider
	const authContext = useContext(AuthContext) as AuthContextType;
	// Assume the user is authenticated
	const user = authContext.user as User;

	// Assume this is being used within a provider for SwimlanesContext
	const swimlanesContext = useContext(SwimlanesContext);

	const queryClient = useQueryClient();
	// Check the cache for the cover letter, but don't fetch it immediately
	// Otherwise every single job card would send a request simultaneously
	let coverLetterQueryData = queryClient.getQueryData<{ coverLetter: string }>([
		"user",
		user.uid,
		"job",
		posting.id,
		"cover-letter"
	]);
	// Save the cover letter in state so we can force the component to re-render with setCoverLetter()
	const [coverLetter, setCoverLetter] = useState<string | undefined>(
		coverLetterQueryData?.coverLetter
	);

	// Non-state flag to indicate whether the context menu is open
	let contextMenuOpen = false;

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	// On right-click, pre-fetch job details and fetch cover letter if not cached
	const prefetchJobData = async (): Promise<void> => {
		// Job details
		queryClient
			.ensureQueryData({
				queryKey: ["user", user.uid, "job", posting.id],
				// TODO: Define these query functions in a single place so they don't need to be copied everywhere they're used
				queryFn: (): Promise<any> => {
					const apiClient = new ApiInterface(API_HTTP.resumeAnalyzer, user);
					return apiClient.get(resumeAnalyzerApiEndpoints.jobDetails, {
						jobId: posting.id
					});
				}
			})
			.catch((err) => console.error(err));
		// Cover letter
		coverLetterQueryData = await queryClient
			.ensureQueryData({
				queryKey: ["user", user.uid, "job", posting.id, "cover-letter"],
				// TODO: Define these query functions in a single place so they don't need to be copied everywhere they're used
				queryFn: (): Promise<any> => {
					const apiClient = new ApiInterface(API_HTTP.resumeAnalyzer, user);
					return apiClient
						.get(
							resumeAnalyzerApiEndpoints.coverLetter,
							{},
							{ params: { jobId: posting.id } }
						)
						.catch((err) => {
							// A 404 error means that the cover letter does not exist (yet)
							if (
								err.name === "AxiosError" &&
								err.code === "ERR_BAD_REQUEST" &&
								err.status === 404
							) {
								return null;
							} else {
								throw err;
							}
						});
				}
			})
			.catch((err) => console.error(err));
		// Do not update state while context menu is open because that will close it
		if (!contextMenuOpen) {
			setCoverLetter(coverLetterQueryData?.coverLetter);
		}
	};

	const copyCoverLetterToClipboard = async (): Promise<void> => {
		// Make sure coverLetter is defined
		if (!coverLetter) return;
		// Convert markdown to HTML and plain text
		const coverLetterHtml = await marked.parse(coverLetter);
		const coverLetterTxt = convertHtmlToText(coverLetterHtml);
		// Create blobs
		const txtBlob = new Blob([coverLetterTxt], { type: "text/plain" });
		const htmlBlob = new Blob([coverLetterHtml], { type: "text/html" });
		// Create clipboard item
		const content = new ClipboardItem({
			"text/plain": txtBlob,
			"text/html": htmlBlob
		});
		// Copy to clipboard
		await navigator.clipboard
			.write([content])
			// Show toast
			.then(() => {
				toast.info("Copied", {
					containerId: "toast-main",
					autoClose: 2000,
					theme: "light"
				});
			})
			// If copy operation fails, log it to the console instead of crashing the page
			.catch((err) => console.error(err));
	};

	return (
		<>
			<Draggable
				draggableId={posting.id}
				index={index}
				isDragDisabled={isModalOpen}
			>
				{(provided, snapshot) => (
					<ContextMenuTrigger
						id={`job-card-context-menu-posting-${posting.id}`}
						disable={isModalOpen}
					>
						<div
							ref={provided.innerRef}
							{...provided.draggableProps}
							{...provided.dragHandleProps}
							style={provided.draggableProps.style}
							className={[
								"card",
								snapshot.isDragging ? " card-dragging" : ""
							].join("")}
							onClick={() => !isModalOpen && setIsModalOpen(true)}
						>
							<div className="flex justify-between items-start">
								<h4 className="card-company">{posting.company}</h4>
								<CardDurationBadge
									jobCreationTimestamp={posting.creationTimestamp}
								/>
							</div>
							<p className="card-title">{posting.title}</p>
							<JobDetailsModal
								job={posting}
								isOpen={isModalOpen}
								onClose={() => setIsModalOpen(false)}
							/>
						</div>
					</ContextMenuTrigger>
				)}
			</Draggable>
			<ContextMenu
				id={`job-card-context-menu-posting-${posting.id}`}
				onShow={(): void => {
					contextMenuOpen = true;
					prefetchJobData();
				}}
				onHide={(): void => {
					// Safe to update state once context menu is closed
					setCoverLetter(coverLetterQueryData?.coverLetter);
					contextMenuOpen = false;
				}}
			>
				<ContextMenuItem
					className="job-card-context-menu-item"
					onClick={() => !isModalOpen && setIsModalOpen(true)}
				>
					<span className="material-symbols-outlined">folder_open</span>
					Open
				</ContextMenuItem>
				<ContextMenuItem
					className={`job-card-context-menu-item ${!coverLetter ? "job-card-context-menu-item-loading" : ""}`}
					disabled={!coverLetter}
					onClick={copyCoverLetterToClipboard}
				>
					<span className="material-symbols-outlined">content_copy</span>
					Copy Cover Letter
				</ContextMenuItem>
				<ContextMenuItem
					className="job-card-context-menu-item"
					onClick={() => swimlanesContext?.moveJobToDone(posting.id)}
				>
					<span className="material-symbols-outlined">scan_delete</span>
					Send to Done
				</ContextMenuItem>
			</ContextMenu>
		</>
	);
}

export default DNDCard;
