// React imports
import React, { ReactNode } from "react";

// Local imports
import SupportEmailAddressLink from "../../components/SupportEmailAddressLink";

// CSS Imports
import "./legal.css";

export default function renderPrivacy(): ReactNode {
	return (
		<div className="legalBody">
			<h1>Keys and Castles Privacy Policy</h1>

			<ol>
				<li>
					<div className="section-title">Introduction</div>
					Keys and Castles ("we", "our", or "us") is committed to protecting
					your privacy. This Privacy Policy explains how we collect, use,
					disclose, and safeguard your information when you use our job
					searching service.
				</li>

				<li>
					<div className="section-title">Information We Collect</div>
					We collect the following types of information:
					<ul>
						<li>Personal information such as your name and email address</li>
						<li>Job searching data</li>
						<li>Personal job history</li>
						<li>
							Information from third-party systems such as Google and Apple
						</li>
					</ul>
				</li>

				<li>
					<div className="section-title">How We Collect Information</div>
					<ul>
						<li>Directly from you when you provide it to us</li>
						<li>Automatically through the use of our service</li>
						<li>From third-party systems such as Google and Apple</li>
					</ul>
				</li>

				<li>
					<div className="section-title">Purpose of Data Collection</div>
					We collect this information to improve your job searching experience
					and provide our service effectively.
				</li>

				<li>
					<div className="section-title">How We Use Your Information</div>
					We use the collected information solely for the purpose of operating
					and improving our job search product.
				</li>

				<li>
					<div className="section-title">Sharing of Your Information</div>
					We do not share your personal information with third parties.
				</li>

				<li>
					<div className="section-title">Data Security</div>
					We implement appropriate technical and organizational measures to
					protect your personal information against unauthorized or unlawful
					processing, accidental loss, destruction, or damage.
				</li>

				<li>
					<div className="section-title">Your Rights</div>
					You have the right to access, correct, and request deletion of your
					personal information. To exercise these rights, please contact us at{" "}
					<SupportEmailAddressLink />.
				</li>

				<li>
					<div className="section-title">Data Retention</div>
					We retain your personal information for 60 days after the end of your
					use of our service.
				</li>

				<li>
					<div className="section-title">International Data Transfers</div>
					This is a US-based product only. We do not transfer your data
					internationally.
				</li>

				<li>
					<div className="section-title">Age Restrictions</div>
					Our service is intended for users who are 18 years of age or older.
				</li>

				<li>
					<div className="section-title">Children's Privacy</div>
					Our service does not address anyone under the age of 18. We do not
					knowingly collect personally identifiable information from children
					under 18.
				</li>

				<li>
					<div className="section-title">Account Deletion</div>
					If you delete your account, your data will be deleted 60 days after
					the request.
				</li>

				<li>
					<div className="section-title">Automated Decision-Making</div>
					We do not engage in automated decision-making or profiling using your
					personal data.
				</li>

				<li>
					<div className="section-title">Changes to This Privacy Policy</div>
					We may update our Privacy Policy from time to time. We will notify you
					of any changes by posting the new Privacy Policy on this page and
					updating the "Last updated" date.
				</li>

				<li>
					<div className="section-title">Contact Us</div>
					If you have any questions about this Privacy Policy, please contact us
					at <SupportEmailAddressLink />.
				</li>

				<li>
					<div className="section-title">Cookies and Tracking Technologies</div>
					We use cookie-based storage in our service. You can choose to disable
					cookies through your individual browser options.
				</li>

				<li>
					<div className="section-title">Compliance with Privacy Laws</div>
					Keys and Castles complies with the California Consumer Privacy Act
					(CCPA). California residents have specific rights regarding their
					personal information.
				</li>

				<li>
					<div className="section-title">Data Breach Procedures</div>
					In the event of a data breach, we will promptly identify and secure
					the breach, notify affected users within 72 hours, and take
					appropriate measures to prevent future occurrences.
				</li>

				<li>
					<div className="section-title">Do Not Track Requests</div>
					At this time, we do not respond to browser "Do Not Track" signals. To
					opt out of certain types of data collection, please email
					<SupportEmailAddressLink />.
				</li>
			</ol>
		</div>
	);
}
