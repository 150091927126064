import React from "react";
import "./Landing.css";
import JobSearchFeature from "../components/JobSearchFeature/JobSearchFeature";
import Hotjar from "../components/Hotjar";
import { Linkedin } from "../components/Analytics/Linkedin";
import { SocialProof, Testimonial } from "../components/SocialProof";
import FreeTrialButton from "../components/FreeTrialButton";
import { Link } from "react-router-dom";

const SOCIAL_PROOF: Testimonial[] = [
	// {
	// 	quote:
	// 		"A thoughtfully customized cover letter serves as a powerful differentiator in the hiring process. By developing targeted content that addresses specific organizational needs and cultural fit, candidates can increase their interview likelihood by up to 50%.",
	// 	source: "HRBrain.ai",
	// 	credentials: "Published - October, 26, 2024"
	// },
	{
		quote:
			"The first follow-up email is naturally the most effective. It brings the highest reply rate — even about 40% higher comparing to the initial email.",
		source: "Megan Kawalkowska",
		credentials: "Content Manager @ Woodpecker (April 30, 2024)"
	},
	{
		quote:
			"You need to make sure that your résumé addresses the employer’s specific challenges and pain-points–otherwise you’re selling a solution the organization doesn’t need.",
		source: "Ben Forstag",
		credentials: "Managing Director, Mac’s List"
	}
];

const JobSearchHeader: React.FC = () => {
	return (
		<div className="job-search-landing">
			<div className="job-search-feature">
				<h2 className="job-search-label">
					<p>JobMap, your job searching assistant. </p>
					<p>This is how it helps you succeed:</p>
				</h2>
				<div className="flex justify-center mt-4">
					{/* Animation duration set to 3.5 seconds because this button is wider than others using glink-link */}
					<FreeTrialButton className="free-trial-button glint-link px-6 py-4 after:![animation-duration:_3.5s]">
						Sign Up
					</FreeTrialButton>
				</div>
				<JobSearchFeature
					title="Smart Job Application Tracking:"
					imageSrc="tracker-image.png"
					isLeftHanded={true}
				>
					<b>Ever forget to follow up or prepare for an interview?</b>
					<p>
						Never lose track of your applications again. Our intuitive tracker
						helps you manage every application, interview, and follow-up in one
						place.
					</p>
					<Link
						to={{
							pathname: "/feature/tracking",
							search: window.location.search
						}}
						className="learn_tracking"
					>
						Learn More
					</Link>
				</JobSearchFeature>

				<JobSearchFeature
					title="AI Cover Letter Assistant:"
					imageSrc="cover-letter-image.png"
					isLeftHanded={false}
				>
					<b>Do you find writing custom cover letters to be time consuming?</b>{" "}
					<p>
						Stop spending hours crafting cover letters. Our AI assistant helps
						you create personalized, professional cover letters in moments,
						letting you focus on what matters.
					</p>
					<Link
						to={{
							pathname: "/feature/coverletter",
							search: window.location.search
						}}
						className="learn_cover_letter"
					>
						Learn More
					</Link>
				</JobSearchFeature>

				<JobSearchFeature
					title="AI Résumé Processing:"
					imageSrc="resume-image.png"
					isLeftHanded={true}
				>
					<b></b>{" "}
					<p>
						We use cutting-edge customized foundational AI models to process
						your résumé and make an impressive response to specific job
						postings.
					</p>
					<Link
						to={{
							pathname: "/feature/resumeprocessor",
							search: window.location.search
						}}
						className="learn_resume_processor"
					>
						Learn More
					</Link>
				</JobSearchFeature>

				<JobSearchFeature
					title="Manual Customization:"
					imageSrc="edit-image.png"
					isLeftHanded={false}
				>
					<b>Ever found AI to be less clever than you need?</b>{" "}
					<p>
						Change it, right on the spot. Our platform gives you interactive
						editability of all your content.
					</p>
					<Link
						to={{
							pathname: "/feature/customize",
							search: window.location.search
						}}
						className="learn_customize"
					>
						Learn More
					</Link>
				</JobSearchFeature>
			</div>

			<div className="job-search-pricing">
				<h2 className="job-search-label">Interested?</h2>
				<div className="flex justify-center max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
					{/* Animation duration set to 3.5 seconds because this button is wider than others using glink-link */}
					<FreeTrialButton className="free-trial-button glint-link after:![animation-duration:_3.5s]">
						Try It For Free
					</FreeTrialButton>
				</div>
			</div>

			<div className="job-search-social-proof">
				<SocialProof socialProof={SOCIAL_PROOF} />
			</div>
		</div>
	);
};

function Landing() {
	return (
		<>
			<Hotjar />
			<Linkedin />
			<JobSearchHeader />
		</>
	);
}

export default Landing;
