// React imports
import { FC, ReactNode } from "react";

// Library imports
import { Link } from "react-router-dom";

// CSS imports
import "./FreeTrialButton.css";

interface FreeTrialButtonProps {
	/**
	 * The path to navigate to when the link is clicked.
	 * @defaultValue `"/login"`
	 */
	path?: string;
	/**
	 * The classes to apply to the anchor element.
	 * @defaultValue `"free-trial-button"`
	 */
	className?: string;
	children?: ReactNode;
}

/**
 * A free trial link which preserves URL search query parameters.
 * Intended to appear as a clickable button.
 * Customize its appearance with the `className` prop.
 */
const FreeTrialButton: FC<FreeTrialButtonProps> = ({
	path: pathname = "/login",
	className = "free-trial-button",
	children
}) => {
	return (
		<Link
			className={className}
			to={{
				pathname,
				search: window.location.search
			}}
		>
			{children}
		</Link>
	);
};

export default FreeTrialButton;
