import React from "react";
import ArrowRight from "../../components/Icons/ArrowRightIcon";
import FileTextIcon from "../../components/Icons/FileTextIcon";
import Hotjar from "../../components/Hotjar";
import FreeTrialButton from "../../components/FreeTrialButton";

const CoverLetterPage = () => {
	return (
		<>
			<Hotjar />
			<div className="min-h-screen bg-gray-50">
				<nav className="bg-white shadow sticky top-0">
					<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
						<div className="flex justify-between h-16">
							<div className="flex items-center">
								<FileTextIcon className="w-8 h-8 text-blue-600" />
								<span className="ml-2 text-xl font-semibold">
									AI Cover Letter Assistant
								</span>
							</div>
						</div>
					</div>
				</nav>

				<main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
					<div className="space-y-12">
						{/* Hero Section */}
						<div className="text-center">
							<h1 className="text-4xl font-bold text-gray-900 mb-4">
								Craft Perfect Cover Letters in Minutes
							</h1>
							<p className="text-xl text-gray-600 max-w-2xl mx-auto">
								Our AI-powered assistant helps you create personalized,
								professional cover letters that catch employers' attention.
							</p>
						</div>

						{/* How It Works */}
						<div className="bg-white rounded-xl shadow-lg overflow-hidden">
							<div className="p-8">
								<h2 className="text-2xl font-bold mb-6">How It Works</h2>
								<div className="grid md:grid-cols-3 gap-8">
									<div className="flex flex-col items-center text-center">
										<div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mb-4">
											<span className="text-blue-600 font-semibold">1</span>
										</div>
										<h3 className="font-semibold mb-2">Upload Résumé</h3>
										<p className="text-gray-600">
											Upload your résumé and let our AI analyze your experience
										</p>
									</div>
									<div className="flex flex-col items-center text-center">
										<div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mb-4">
											<span className="text-blue-600 font-semibold">2</span>
										</div>
										<h3 className="font-semibold mb-2">Add Job Details</h3>
										<p className="text-gray-600">
											Paste the job description to match requirements
										</p>
									</div>
									<div className="flex flex-col items-center text-center">
										<div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mb-4">
											<span className="text-blue-600 font-semibold">3</span>
										</div>
										<h3 className="font-semibold mb-2">Just click enter</h3>
										<p className="text-gray-600">
											Get your customized cover letter in moments
										</p>
									</div>
								</div>
							</div>
						</div>

						{/* Features */}
						<div className="grid md:grid-cols-2 gap-8">
							<div className="bg-white rounded-xl shadow-lg p-8">
								<h2 className="text-2xl font-bold mb-6">Key Features</h2>
								<ul className="space-y-4">
									<li className="flex items-start">
										<ArrowRight className="w-5 h-5 text-blue-600 mt-1 mr-2" />
										<div>
											<h3 className="font-semibold">
												AI-Powered Personalization
											</h3>
											<p className="text-gray-600">
												Tailored content that matches job requirements and
												company culture
											</p>
										</div>
									</li>
									<li className="flex items-start">
										<ArrowRight className="w-5 h-5 text-blue-600 mt-1 mr-2" />
										<div>
											<h3 className="font-semibold">Industry-Specific Text</h3>
											<p className="text-gray-600">
												Professional language optimized for your industry
											</p>
										</div>
									</li>
									<li className="flex items-start">
										<ArrowRight className="w-5 h-5 text-blue-600 mt-1 mr-2" />
										<div>
											<h3 className="font-semibold">Keyword Optimization</h3>
											<p className="text-gray-600">
												Strategic placement of relevant keywords for ATS systems
											</p>
										</div>
									</li>
								</ul>
							</div>

							<div className="bg-white rounded-xl shadow-lg p-8">
								<h2 className="text-2xl font-bold mb-6">Benefits</h2>
								<ul className="space-y-4">
									<li className="flex items-start">
										<ArrowRight className="w-5 h-5 text-blue-600 mt-1 mr-2" />
										<div>
											<h3 className="font-semibold">Save Time</h3>
											<p className="text-gray-600">
												Create custom cover letters in moments instead of hours
											</p>
										</div>
									</li>
									<li className="flex items-start">
										<ArrowRight className="w-5 h-5 text-blue-600 mt-1 mr-2" />
										<div>
											<h3 className="font-semibold">Higher Response Rate</h3>
											<p className="text-gray-600">
												Increase your chances of getting interviews
											</p>
										</div>
									</li>
									<li className="flex items-start">
										<ArrowRight className="w-5 h-5 text-blue-600 mt-1 mr-2" />
										<div>
											<h3 className="font-semibold">Professional Quality</h3>
											<p className="text-gray-600">
												Polished, error-free writing that impresses employers
											</p>
										</div>
									</li>
								</ul>
							</div>
						</div>

						{/* CTA Section */}
						<div className="bg-blue-600 rounded-xl shadow-lg p-8 text-center text-white">
							<h2 className="text-3xl font-bold mb-4">Ready to Get Started?</h2>
							<p className="text-xl mb-6">
								Create your first AI-powered cover letter today
							</p>
							<FreeTrialButton className="feature-free-trial-button cover-letter-free-trial-button glint-link">
								Try It Now
							</FreeTrialButton>
						</div>
					</div>
				</main>
			</div>
		</>
	);
};

export default CoverLetterPage;
