import React from "react";

const CardDurationBadge = ({
	jobCreationTimestamp
}: {
	jobCreationTimestamp: Date;
}) => {
	const calculateDuration = (jobCreationTimestamp: Date) => {
		// Guard against undefined or invalid dates
		if (
			!jobCreationTimestamp ||
			!(jobCreationTimestamp instanceof Date) ||
			isNaN(jobCreationTimestamp.getTime())
		) {
			console.log(jobCreationTimestamp);
			return "0s";
		}

		const now = new Date();
		const timestamp = new Date(jobCreationTimestamp);
		const diffInSeconds = Math.floor(
			(now.getTime() - timestamp.getTime()) / 1000
		);

		// Less than a minute
		if (diffInSeconds < 60) {
			return `${diffInSeconds}s`;
		}

		// Less than an hour
		const diffInMinutes = Math.floor(diffInSeconds / 60);
		if (diffInMinutes < 60) {
			return `${diffInMinutes}m`;
		}

		// Less than a day
		const diffInHours = Math.floor(diffInMinutes / 60);
		if (diffInHours < 24) {
			return `${diffInHours}h`;
		}

		// Days
		const diffInDays = Math.floor(diffInHours / 24);
		return `${diffInDays}d`;
	};

	/**********
	 * calculatebadgeColorStyle
	 * returns - a React.CSSProperty - With Color and Backgound customly selected for good contrast and matching the following rules.
	 * - 0-20 badge will be green
	 * - 20-30 badge will be yellow
	 * - 30+ badge will be red
	 * --
	 * This is to be used by the user to give them perspective on how to use the current job card.
	 */
	const calculatebadgeColorStyle = (
		jobCreationTimestamp: Date
	): React.CSSProperties => {
		const green = `#4d9538`;
		const yellow = `#EBD534`;
		const red = `#EB3434`;
		const white = `#FFFFFF`;
		const black = `#000000`;

		// Guard against undefined or invalid dates
		if (
			!jobCreationTimestamp ||
			!(jobCreationTimestamp instanceof Date) ||
			isNaN(jobCreationTimestamp.getTime())
		) {
			console.log(
				`Invalid parameter jobCreationTimestamp:[${jobCreationTimestamp}]`
			);
			return {
				color: red,
				backgroundColor: black
			};
		}

		//Buidl the Timestamps for the comparision
		const now = new Date().getTime();
		const cardCreation = new Date(jobCreationTimestamp).getTime();

		//Calculate Age in days.
		const ageInSeconds = Math.floor((now - cardCreation) / 1000);
		const ageInDays = Math.floor(
			Math.floor(Math.floor(ageInSeconds / 60) / 60) / 24
		);

		// Greater than 30 - Defult
		var background: string = red;
		var foreground: string = white;

		// Less than 20 days old
		if (ageInDays < 20) {
			background = green;
			foreground = white;
		}
		if (20 <= ageInDays && ageInDays <= 30) {
			background = yellow;
			foreground = black;
		}

		return {
			color: foreground,
			backgroundColor: background
		};
	};

	return (
		<span
			className="inline-flex items-center rounded-full px-2 py-1 text-xs font-medium text-white"
			style={calculatebadgeColorStyle(jobCreationTimestamp)}
		>
			{calculateDuration(jobCreationTimestamp)}
		</span>
	);
};

export default CardDurationBadge;
